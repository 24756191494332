import {createTheme} from '@mui/material/styles';

const lightTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    fontSize: '1.5rem',
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                }
            }
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                }
            }
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0E4D73',
            secondary: '#0B3954',
        }
    },
    shape: {
        borderRadius: 10,
    },

    typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
            fontSize: '3.5rem',
            lineHeight: '4.5rem',
        },
        h2: {
            fontSize: '2.5rem',
            lineHeight: '3.5rem',
        },
        h3: {
            fontSize: '1.5rem',
            lineHeight: '2.5rem',
        },
        h4: {
            fontSize: '1.2rem',
            lineHeight: '2rem',
        }
    }
});

export default lightTheme;
