import {Box, Typography} from "@mui/material";
import styles from '../../styles/Logo.module.css'
import Link from "next/link";
import React from "react";

export default function Logo() {
    return (
        <Box display={"flex"} flexDirection={"row"} alignSelf={"center"}>
            <Link href={'/'}>
                <img alt="InstaAlert Logo" src={"images/InstaAlert.me.svg"} className={styles.logo} />
            </Link>
        </Box>
    )
}
